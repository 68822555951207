import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import Filtros from "../Filtros";

export default function Header() {
  /** Render **/
  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex gap-2 justify-content-end">
        <button className="btn btn-add">
          <span className="add">
            <FontAwesomeIcon
              icon={faFileExcel}
              size="1x"
              className="action"
              color="#215732"
            />
          </span>
          Pérdidas
        </button>
        <button className="btn btn-add">
          <span className="add">
            <FontAwesomeIcon
              icon={faFileExcel}
              size="1x"
              className="action"
              color="#215732"
            />
          </span>
          Culpa Recomotor
        </button>
        <button className="btn btn-add">
          <span className="add">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              size="1x"
              className="action"
              color="#215732"
            />
          </span>
          Avisos
        </button>
      </div>
      <Filtros />
    </div>
  );
}
