export const SidebarData = [
  {
    title: "Panel de Control General",
    path: "/control-general",
    cName: "nav-text",
    permission: 0,
  },
  {
    title: "Control Envíos",
    path: "/control-envios",
    cName: "nav-text",
    permission: 0,
    disabled: true,
  },
  {
    title: "Incidencias",
    path: "/incidencias",
    cName: "nav-text",
    permission: 0,
  },
  {
    title: "Comunicación",
    path: "/comunicacion",
    cName: "nav-text",
    permission: 0,
    disabled: true,
  },
  {
    title: "Personalización",
    path: "/personalizacion",
    cName: "nav-text",
    permission: 0,
    disabled: true,
  },
  {
    title: "Datos Logísticos",
    path: "/datos-logisticos",
    cName: "nav-text",
    permission: 0,
    disabled: true,
  },
  {
    title: "Análisis de Datos",
    path: "/analisis-datos",
    cName: "nav-text",
    permission: 0,
    disabled: true,
  },
  {
    title: "Facturación",
    path: "/facturacion",
    cName: "nav-text",
    permission: 0,
    disabled: true,
  },
];
