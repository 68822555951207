import { useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

export default function Filtros() {
  /** States **/
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [responsable, setResponsable] = useState(-1);
  const [creadoPor, setCreadoPor] = useState(-1);
  const [tipoIncidencia, setTipoIncidencia] = useState(-1);
  const [culpa, setCulpa] = useState(-1);
  const [proveedor, setProveedor] = useState(-1);
  const [tipoPieza, setTipoPieza] = useState(-1);
  const [solProveedor, setSolProveedor] = useState(-1);
  const [solCliente, setSolCliente] = useState(-1);

  const tipoIncidenciaList = [
    { id: 2, name: "Garantía" },
    { id: 3, name: "Envio" },
    { id: 4, name: "Desestimiento" },
    { id: 5, name: "TA" },
    { id: 6, name: "Producto incorrecto" },
    { id: 7, name: "Fallo de stock" },
    { id: 8, name: "Casco" },
    { id: 9, name: "Producto incompleto" },
    { id: 10, name: "Interna" },
  ];
  const proveedorList = [
    { id: 1, name: "EUROMASTER P056, Cuenca" },
    { id: 2, name: "Feu vert (AUTOCENTRO 061), CORUÑA II" },
    { id: 3, name: "ELECTROMECANICA MADERO SL" },
  ];
  const culpaList = [
    {
      id: 1,
      name: "Proveedor",
    },
    {
      id: 2,
      name: "Recomotor",
    },
    {
      id: 3,
      name: "Interna",
    },
  ];
  const tipoPiezaList = [
    { id: 1, name: "ANTENA" },
    { id: 2, name: "ANTIRROBO" },
    { id: 3, name: "BARRAS" },
    { id: 4, name: "TECHO" },
    { id: 5, name: "BASE DE ANTENA" },
    { id: 6, name: "BATERIA" },
    { id: 7, name: "BOMBILLAS" },
    { id: 8, name: "CABALLETE" },
    { id: 9, name: "CADENAS" },
    { id: 10, name: "CLAXON" },
  ];

  /** Render **/
  return (
    <div className="d-flex gap-3 flex-wrap">
      <div className="d-flex flex-column">
        <small>Fecha</small>
        <div className="d-flex gap-3">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <DatePicker
              disableFuture
              label=""
              value={startDateValue}
              onChange={(newValue) => {
                setStartDateValue(newValue);
              }}
              mask={"__/__/____"}
              inputFormat="dd/MM/yyyy"
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className="date-picker" style={{ position: "relative" }}>
                  <input
                    className="form-control"
                    ref={inputRef}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                </div>
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <DatePicker
              disableFuture
              label=""
              value={endDateValue}
              onChange={(newValue) => {
                setEndDateValue(newValue);
              }}
              mask={"__/__/____"}
              inputFormat="dd/MM/yyyy"
              minDate={startDateValue}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className="date-picker" sx={{ position: "relative" }}>
                  <input
                    className="form-control"
                    ref={inputRef}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                </div>
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="d-flex flex-column">
        <small>Responsable</small>
        <Select
          size="small"
          name="responsable"
          id="responsable"
          onChange={(e) => setResponsable(e.target.value)}
          value={responsable ? responsable : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
          <MenuItem key={1} value={1}>
            Maru
          </MenuItem>
        </Select>
      </div>
      <div className="d-flex flex-column">
        <small>Creado por</small>
        <Select
          size="small"
          name="creadoPor"
          id="creadoPor"
          onChange={(e) => setCreadoPor(e.target.value)}
          value={creadoPor ? creadoPor : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
          <MenuItem key={1} value={1}>
            Maru
          </MenuItem>
        </Select>
      </div>
      <div className="d-flex flex-column">
        <small>Tipo</small>
        <Select
          size="small"
          name="tipoIncidencia"
          id="tipoIncidencia"
          onChange={(e) => setTipoIncidencia(e.target.value)}
          value={tipoIncidencia ? tipoIncidencia : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
          {tipoIncidenciaList.map((tipo) => (
            <MenuItem key={tipo.id} value={tipo.id}>
              {tipo.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="d-flex flex-column">
        <small>Culpa</small>
        <Select
          size="small"
          name="culpa"
          id="culpa"
          onChange={(e) => setCulpa(e.target.value)}
          value={culpa ? culpa : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
          {culpaList.map((culpa) => (
            <MenuItem key={culpa.id} value={culpa.id}>
              {culpa.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="d-flex flex-column">
        <small>Proveedor</small>
        <Select
          size="small"
          name="proveedor"
          id="proveedor"
          onChange={(e) => setProveedor(e.target.value)}
          value={proveedor ? proveedor : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
          {proveedorList.map((proveedor) => (
            <MenuItem key={proveedor.id} value={proveedor.id}>
              {proveedor.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="d-flex flex-column">
        <small>Tipo de pieza</small>
        <Select
          size="small"
          name="tipoPieza"
          id="tipoPieza"
          onChange={(e) => setTipoPieza(e.target.value)}
          value={tipoPieza ? tipoPieza : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
          {tipoPiezaList.map((tipoPieza) => (
            <MenuItem key={tipoPieza.id} value={tipoPieza.id}>
              {tipoPieza.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="d-flex flex-column">
        <small>Sol. Proveedor</small>
        <Select
          size="small"
          name="solProveedor"
          id="solProveedor"
          onChange={(e) => setSolProveedor(e.target.value)}
          value={solProveedor ? solProveedor : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
        </Select>
      </div>
      <div className="d-flex flex-column">
        <small>Sol. Cliente</small>
        <Select
          size="small"
          name="solCliente"
          id="solCliente"
          onChange={(e) => setSolCliente(e.target.value)}
          value={solCliente ? solCliente : -1}
          style={{ height: "43px", minWidth: "150px" }}
        >
          <MenuItem key={1} value={-1}>
            {"Todos"}
          </MenuItem>
        </Select>
      </div>
      <div>
      <small>Buscador</small>
        <div className="position-relative">
          <input
            type="text"
            name="searcher"
            className="form-control"
            style={{ width: "250px" }}
          />
          <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
        </div>
      </div>
      <span
        title="Reset filtros"
        style={{ cursor: "pointer" }}
        className="d-flex align-items-end mb-2"
      >
        <FontAwesomeIcon
          icon={faFilterCircleXmark}
          color="#dc3545"
          className="icon"
        />
      </span>
    </div>
  );
}
