import ActionsMenu from "../../../shared/components/ActionsMenu";
import Header from "../Header";
import Info from "./Info";
import InfoModal from "../../../shared/components/InfoModal";
import Pagination from "@mui/material/Pagination";
import incidenciasEnvios from "../../../../../../data/Incidencias/incidencias_envios.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getActions } from "./actions";
import { useState } from "react";

export default function IncidenciasEnvio() {
  /** States **/
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [currentTableItem, setCurrentTableItem] = useState(null);
  const [tab, setTab] = useState("enCurso");

  /** Methods **/
  const handleChangeTab = (_e, tab) => setTab(tab);
  const handleAction = (_action, tableItem) => {
    setOpenInfoModal(true);
    setCurrentTableItem(tableItem);
  };
  const handleCloseNewFromFile = () => {
    setOpenInfoModal(false);
  };

  /** Render **/
  return (
    <div className="d-flex flex-column gap-3">
      <Header />
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="Incidencias Tabs">
            <Tab label="En Curso" value="enCurso" disabled={false} />
            <Tab label="Finanzas" value="finanzas" disabled={false} />
            <Tab label="Archivadas" value="archivadas" disabled={false} />
          </TabList>
        </Box>
        <TabPanel value="enCurso">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center" style={{ width: "100px" }}>
                    Nº
                  </th>
                  <th className="text-center">Fecha</th>
                  <th className="text-center">Factura</th>
                  <th className="text-center">Cliente</th>
                  <th className="text-center">Proveedor</th>
                  <th className="text-center">Transportista</th>
                  <th className="text-center">Pieza</th>
                  <th className="text-center">Venta</th>
                  <th className="text-center">Envío</th>
                  <th className="text-center">Responsable</th>
                  <th className="text-center">Creada por</th>
                  <th className="text-center">Motivo</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Demora</th>
                  <th className="text-center">SP</th>
                  <th className="text-center">ST</th>
                  <th className="text-center">SC</th>
                  <th className="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {incidenciasEnvios.tabla?.map((tabla) => {
                  return (
                    <tr key={tabla.id}>
                      <td className="text-cente">{tabla.id}</td>
                      <td className="text-center">{tabla.fecha}</td>
                      <td className="text-center">{tabla.factura}</td>
                      <td className="text-center text-green">
                        <strong>{tabla.cliente}</strong>
                      </td>
                      <td className="text-center text-green">
                        <strong>{tabla.proveedor}</strong>
                      </td>
                      <td className="text-center text-green">
                        <strong>{tabla.transportista}</strong>
                      </td>
                      <td className="text-center">{tabla.pieza}</td>
                      <td className="text-center">{tabla.venta}</td>
                      <td className="text-center">{tabla.envio}</td>
                      <td className="text-center">{tabla.responsable}</td>
                      <td className="text-center">{tabla.creadaPor}</td>
                      <td className="text-center">{tabla.motivo}</td>
                      <td className="text-center text-danger">
                        {tabla.estado}
                      </td>
                      <td
                        className={`text-center
                      ${tabla.demora === "ALTA" && "text-danger"}
                      ${tabla.demora === "BAJA" && "text-warning"}`}
                        style={{ color: tabla.demora === "MEDIA" && "#FF914D" }}
                      >
                        <strong>{tabla.demora}</strong>
                      </td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          color={tabla.sp ? "#03BF62" : "#DC3545"}
                        />
                      </td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          color={tabla.st ? "#03BF62" : "#DC3545"}
                        />
                      </td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          color={tabla.sc ? "#03BF62" : "#DC3545"}
                        />
                      </td>
                      <td className="text-center">
                        <ActionsMenu
                          options={getActions()}
                          onAction={(action) => handleAction(action, tabla)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <Pagination count={1} page={1} />
          </div>
        </TabPanel>
        <TabPanel value="finanzas">
          <div
            className="d-flex justify-content-center p-4 rounded"
            style={{ background: "#F5F5F7" }}
          >
            No hay incidencias de finanzas
          </div>
        </TabPanel>
        <TabPanel value="archivadas">
          <div
            className="d-flex justify-content-center p-4 rounded"
            style={{ background: "#F5F5F7" }}
          >
            No hay incidencias archivadas
          </div>
        </TabPanel>
      </TabContext>
      {openInfoModal && (
        <InfoModal
          state={openInfoModal}
          title={`Información de Envio ${currentTableItem.id}`}
          content={<Info data={currentTableItem} />}
          width={"md"}
          onClose={() => {
            handleCloseNewFromFile();
          }}
        />
      )}
    </div>
  );
}
