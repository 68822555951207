import { useState } from "react";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IncidenciasPedidos from "./components/IncidenciasPedidos";
import IncidenciasEnvios from "./components/IncidenciasEnvios";

export default function Incidencias() {
  /** States **/
  const [tab, setTab] = useState("incidenciasPedidos");

  /** Methods **/
  const handleChangeTab = (_e, tab) => setTab(tab);

  /** Render **/
  return (
    <div className="content">
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="Incidencias Tabs">
            <Tab label="Incidencias Pedidos" value="incidenciasPedidos" disabled={false} />
            <Tab label="Incidencias Envíos" value="incidenciasEnvios" disabled={false} />
          </TabList>
        </Box>
        <TabPanel value="incidenciasPedidos">
          <IncidenciasPedidos />
        </TabPanel>
        <TabPanel value="incidenciasEnvios">
          <IncidenciasEnvios />
        </TabPanel>
      </TabContext>
    </div>
  );
}
