import ActionsMenu from "../../../shared/components/ActionsMenu";
import Header from "../Header";
import Info from "./Info";
import InfoModal from "../../../shared/components/InfoModal";
import Pagination from "@mui/material/Pagination";
import incidenciasPedidos from "../../../../../../data/Incidencias/incidencias_pedidos.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getActions } from "./actions";
import { useState } from "react";

export default function IncidenciasPedidos() {
  /** States **/
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [currentTableItem, setCurrentTableItem] = useState(null);
  const [tab, setTab] = useState("enCurso");

  /** Methods **/
  const handleChangeTab = (_e, tab) => setTab(tab);
  const handleAction = (_action, tableItem) => {
    setOpenInfoModal(true);
    setCurrentTableItem(tableItem);
  };
  const handleCloseNewFromFile = () => {
    setOpenInfoModal(false);
  };

  /** Render **/
  return (
    <div className="d-flex flex-column gap-3">
      <Header />
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="Incidencias Tabs">
            <Tab label="En Curso" value="enCurso" disabled={false} />
            <Tab label="Finanzas" value="finanzas" disabled={false} />
            <Tab label="Archivadas" value="archivadas" disabled={false} />
          </TabList>
        </Box>
        <TabPanel value="enCurso">
          <div className="table-responsive" style={{marginTop: "-1rem"}}>
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center" style={{ width: "100px" }}>
                    Nº
                  </th>
                  <th className="text-center">Fecha</th>
                  <th className="text-center">Tipo</th>
                  <th className="text-center">Factura</th>
                  <th className="text-center">Cliente</th>
                  <th className="text-center">Proveedor</th>
                  <th className="text-center">Pieza</th>
                  <th className="text-center">€ Venta</th>
                  <th className="text-center">Envio</th>
                  <th className="text-center">Resp</th>
                  <th className="text-center">Creada por</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">SP</th>
                  <th className="text-center">SC</th>
                  <th className="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {incidenciasPedidos.tabla?.map((tabla) => {
                  return (
                    <tr key={tabla.id}>
                      <td className="text-cente">{tabla.id}</td>
                      <td className="text-center">{tabla.fecha}</td>
                      <td className="text-center">{tabla.tipo}</td>
                      <td className="text-center text-green">
                        {tabla.factura}
                      </td>
                      <td className="text-center">
                        <p className="text-green">{tabla.cliente?.nombre}</p>
                        <p>{tabla.cliente?.telefono}</p>
                      </td>
                      <td className="text-center">
                        <p className="text-green">{tabla.proveedor?.nombre}</p>
                        <p>{tabla.proveedor?.telefono}</p>
                      </td>
                      <td className="text-center">{tabla.pieza}</td>
                      <td className="text-center">{tabla.venta}</td>
                      <td className="text-center">{tabla.envio || "-"}</td>
                      <td className="text-center">{tabla.resp}</td>
                      <td className="text-center">{tabla.creada_por}</td>
                      <td className="text-center text-danger">
                        <strong>{tabla.estado}</strong>
                      </td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          color="#DC3545"
                        />
                      </td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          color="#DC3545"
                        />
                      </td>
                      <td className="text-center">
                        <ActionsMenu
                          options={getActions()}
                          onAction={(action) => handleAction(action, tabla)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <Pagination count={1} page={1} />
          </div>
        </TabPanel>
        <TabPanel value="finanzas">
          <div className="d-flex justify-content-center p-4 rounded" style={{ background: "#F5F5F7" }}>
            No hay incidencias de finanzas
          </div>
        </TabPanel>
        <TabPanel value="archivadas">
          <div className="d-flex justify-content-center p-4 rounded" style={{ background: "#F5F5F7" }}>
            No hay incidencias archivadas
          </div>
        </TabPanel>
      </TabContext>
      {openInfoModal && (
        <InfoModal
          state={openInfoModal}
          title={`Información de Envio ${currentTableItem.id}`}
          content={<Info data={currentTableItem} />}
          width={"md"}
          onClose={() => {
            handleCloseNewFromFile();
          }}
        />
      )}
    </div>
  );
}
